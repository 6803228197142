<template>
  <div class="container">
      <h2>Thank you! I look forward to reading together!</h2>
  </div>
</template>

<script>
export default {

}
</script>

<style>
h2 {
    margin: auto;
    margin-top: 2rem;
}
</style>